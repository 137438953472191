<template>
	<index-about />
</template>

<script>
import IndexAbout from '@/components/About/Index';

export default {
	name: 'About',
	components: {
		IndexAbout
	}
}
</script>

<style scoped></style>
